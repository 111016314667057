<template>
  <v-container @click="skip" class="green-background fill-height start-height" >
    <v-row class="blue-border">
      <v-col cols="12" >
<!--        <v-progress-linear :value="progress"-->
<!--                           color="#FFDB23"-->
<!--                           class="progress-block"-->
<!--        ></v-progress-linear>-->

        <div class="loader">
          <div class="loader__element"></div>
        </div>
      </v-col>
    </v-row>
    <v-row class="blue-border">
      <v-col align="center" class="fill-height">
        <v-img
          max-width="70%"
          max-height="500px"
          contain
          :src="require('../assets/logo-main-page.svg')"
          class="logo"
        >
        </v-img>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

export default {
  name: 'MainPage',
  components: {},
  data: () => ({
    alert: true,
    dialog: false,
    progress: 0,
    timeout: null
  }),
  methods: {
    toggleMenu () {
      this.$refs.refMenu.toggle()
    },
    startProgress () {
      this.progress = 0
      this.timeout = setTimeout(this.skip, 2500)
    },
    skip () {
      clearTimeout(this.timeout)
      this.$router.push('/order/1')
    }
  },
  mounted: function () {
    this.$nextTick(() => {
      this.startProgress()
    })
  }
}
</script>
<style>
.container.fill-height.start-height{
  align-items: flex-start;
}

.logo {
  top: -50px;
}

.green-background {
  background: linear-gradient(180deg, #0EAE00 0%, #17D006 25%, #11D800 65.62%, #0EAE00 100%);
}

:root {
  --loader-color: #FFDB23;
  --back-color: #515254;
  --time: 2.5s;
  --size: 5px;
}

.loader {
  margin: 40px 19px 0;
}

.loader__element {
  height: var(--size);
  width: 100%;
  background: var(--back-color);
}

.loader__element:before {
  content: '';
  display: block;
  background-color: var(--loader-color);
  height: var(--size);
  width: 0;
  animation: getWidth var(--time) linear;
  animation-fill-mode: forwards;
}

@keyframes getWidth {
  100% { width: 100%; }
}

</style>
